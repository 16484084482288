import { observable } from 'mobx'

export const LoadingContext = observable({
  loading: true,
  exit: true,
  setLoading: () => {
    LoadingContext.loading = true
    LoadingContext.exit = false
  },
  disabledLoading: () => {
    LoadingContext.loading = false
  },
})
