const React = require('react')
const loading = require('./src/components/loading/context')

exports.onRouteUpdate = () => {
  // loading.LoadingContext.setLoading()
}

exports.onInitialClientRender = () => {
  console.log('ReactDOM.render has executed')
  loading.LoadingContext.disabledLoading()
}

exports.onRouteUpdateDelayed = () => {
  console.log('We can show loading indicator now')
  loading.LoadingContext.disabledLoading()
}
